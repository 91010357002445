/* eslint-disable no-unused-vars */
import { h } from "preact";
import { SiteModal } from "../SiteModal/SiteModalContext";
import SiteModalContent from "../SiteModal/SiteModalContent";

import "../../style/components/blocks/history_item.css";

export default function HistoryItem(props) {
  const { date, number, balance, teamMembers, desktop, mobile, view, url } =
    props;
  return (
    <div className="history-item">
      <div className="history-item__row">
        <div className="history-item__block">
          <span className="history-item__label">Date</span>
          <span className="history-item__value history-item__date">{date}</span>
        </div>
        <div className="history-item__block">
          <span className="history-item__label">Invoice #</span>
          <span className="history-item__value history-item__invoice">
            {number}
          </span>
        </div>
        <div className="history-item__block">
          <span className="history-item__label">Amount</span>
          <span className="history-item__value history-item__amount">
            {balance}
          </span>
        </div>
        {/* <div className="history-item__block">
          <span className="history-item__label">Team Members</span>
          <span className="history-item__value history-item__team-members">
            {teamMembers}
          </span>
        </div>
        <div className="history-item__block">
          <span className="history-item__label">Desktop</span>
          <span className="history-item__value history_item__desktop">
            {desktop}
          </span>
        </div>
        <div className="history-item__block">
          <span className="history-item__label">Mobile</span>
          <span className="history-item__value history-item__mobile">
            {mobile}
          </span>
        </div> */}
        <div className="history-item__block history-item__block--actions">
          <div className="history-item__actions">
            {/* <div className="history-item__view">
              <SiteModal>
                <SiteModalContent trigger={"View"}>
                  <div className="history-item__view-content">
                    <iframe
                      src={url}
                      className="history-item__view-iframe"
                      height="100%"
                      width="100%"
                      frameborder="0"
                    />
                  </div>
                </SiteModalContent>
              </SiteModal>
            </div> */}
            <div className="history-item__pdf">
              <a
                href={url}
                className="history-item__pdf-link"
                rel="noopener noreferrer"
              >
                PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
