import { h } from 'preact';

const Included = ({ content }) => {
	console.log('content: ', content);
	return (
	<section id="included" class={`block included`}>
		<h2 class={`-title display1`}>{content?.featured_title[0].text}</h2>
		<ul class={`list`}>
		{
			content.featured_items?.map((element, index) => {
				return (
					<li key={index}>
						<p class={`image`}>
							<img
								src={element?.featured_image?.url}
								alt=""
								width="115"
								height="115"
							/>
						</p>
						<h3 class={`-title display4-lite`}>
							{element?.featured_label[0]?.text}
						</h3>
						<p class={`-summary bodysmall`}>
							{element?.featured_desc[0]?.text}
						</p>
					</li>
				)
			})
		}
		</ul>
	</section>
)
};

export default Included;
