/* eslint-disable no-unused-vars */
import { h, createContext } from "preact";
import { useState, useEffect, useMemo } from "preact/hooks";

export const PlanSelectionContext = createContext({});

export const PlanSelectionProvider = ({ children }) => {
  // Plan Selection
  const [planSelection, setPlanSelection] = useState(null);
  const [planDetails, setPlanDetails] = useState({
    type: "full",
    ctaLabel: "Continue to Checkout",
  });

  // Order Summary
  const [protectionPlanLabel, setProtectionPlanLabel] = useState(null);
  const [deviceCountLabel, setDeviceCountLabel] = useState(null);
  const [deviceCountTotal, setDeviceCountTotal] = useState(null);
  const [monthlyTotalLabel, setMonthlyTotalLabel] = useState(null);
  const [monthlyTotal, setMonthlyTotal] = useState(null);
  const [todaysTotalLabel, useTodaysTotalLabel] = useState(null);
  const [todaysTotal, setTodaysTotal] = useState(null);
  const [payByComission, setPayByComission] = useState(false);
  const [formData, setFormData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [ isFormValid, setIsFormValid ] = useState(false);

  return (
    <PlanSelectionContext.Provider
      value={{
        formData,
        setFormData,
        planDetails,
        setPlanDetails,
        planSelection,
        setPlanSelection,
        protectionPlanLabel,
        setProtectionPlanLabel,
        deviceCountLabel,
        setDeviceCountLabel,
        deviceCountTotal,
        setDeviceCountTotal,
        monthlyTotalLabel,
        setMonthlyTotalLabel,
        monthlyTotal,
        setMonthlyTotal,
        todaysTotalLabel,
        useTodaysTotalLabel,
        todaysTotal,
        setTodaysTotal,
        payByComission,
        setPayByComission,
        buttonDisabled,
        setButtonDisabled,
        isFormValid,
        setIsFormValid
      }}
    >
      {children}
    </PlanSelectionContext.Provider>
  );
};
