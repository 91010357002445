import {h } from 'preact';
import { useWindowSize } from '../../Utilities/window';
import { useState } from 'preact/hooks';

const Software = ({ doc }) => {
	const size = useWindowSize();
	const installVersion = [
		{
			label: 'Windows',
			value: doc.windows_installer.url
			// value: 'https://cyberprotection.aflac.com/downloads/AflacCyberProtection.exe'
		},
		{
			label: 'Mac OS',
			value: doc.mac_installer.url
			// value: 'https://cyberprotection.aflac.com/downloads/AflacCyberProtection.zip'
		}
	];
	const [ downloadLink, setDownloadLink ] = useState();
	const handleChange = (e) => { setDownloadLink(e.target.value) };
	return (<>
			{/* <h4>Software</h4> */}
			{
				size.width > 768 && 
				<>
					<div class="input_container col-1-2 margin-y-1">
						<select value={downloadLink} onChange={handleChange} class={`software`}>
							<option value="">Select Platform</option>
							{installVersion.map((option) => (
								<option value={option.value}>{option.label}</option>
							))}
						</select>
						<label for="platform">Select Platform</label>
					</div>
					<a class={`button -blue margin-y-1 margin-x-auto`} href={downloadLink}>Download</a>
				</>
			}
				{/* <h5>
					Installation Walkthrough
				</h5> */}
				{/* { downloadLink == installVersion[0].value ? (
				<>
				<video width="250" controls poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2112711598001/0c25fd32-d6b6-4ff7-96c1-24e3ea8f6a0e/2dd6ccea-1cf4-46b3-b6fd-cf4fe615d557/1280x720/match/image.jpg">
					<source src="https://cyberprotection.aflac.com/media/win_install.mp4" type="video/mp4"/>
				</video>
				</>
				) : downloadLink == installVersion[1].value ? (
				<>
				<video width="250" controls poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2112711598001/0c25fd32-d6b6-4ff7-96c1-24e3ea8f6a0e/2dd6ccea-1cf4-46b3-b6fd-cf4fe615d557/1280x720/match/image.jpg">
					<source src="https://cyberprotection.aflac.com/media/mac_install.mp4" type="video/mp4"/>
				</video>
				</>
				) : (<></>)
				} */}
	</>)
}

export default Software;