import { h } from 'preact';

const BlogSection = ({ blogs }) => {
    return (
    <section id="blog" class={`block blog`}>
        <h2 class={`-title`}>
            Latest from the Newsletter
        </h2>
        {
            blogs.map((item, index) => {
                return (
                    <article key={index}>
                        <img
                            src={item.data.post_image.url} />
                        <content>
                            <small>
                                {item.data.post_date + ' ' + item.data.post_category}
                            </small>
                            <h3>
                                {item.data.post_title[0].text}
                            </h3>
                            <p>
                                {item.data.post_blurb[0].text}
                            </p>
                            <p>
                                <a href={`./blog/${item.uid}`}>
                                    Read Article
                                </a>
                            </p>
                        </content>
                    </article>
                )
            })
        }
    </section>
    )
};
export default BlogSection;
