import { h } from "preact";
import Button from "../Button";

import "../../style/components/blocks/button_bar.css";

const Pricing = ({ content }) => {
  const {
    pricing_image,
    pricing_title1,
    pricing_title2,
    pricing_text1,
    pricing_text2,
  } = content;
  return (
    <section class="row home-pricing">
      <span
        style="background-color: var(--brand-blue); color: var(--brand-white);"
        class="col-2-3 padding-large space-between"
      >
        <h2 class="margin-y-2 home-pricing__title">
          {pricing_title1?.[0].text}
        </h2>
        <p style="color: var(--brand-white);" class="margin-y-1">
          {pricing_text1?.[0].text}
        </p>
        <h4 class="display4 margin-y-1">{pricing_title2?.[0].text}</h4>
        <p style="color: var(--brand-white);" class="margin-y-1">
          {pricing_text2?.[0].text}
        </p>

        <h4 class="display3 margin-y-1 home-pricing__button-bar-title">
          Ready to get started?
        </h4>
        <div className="button-bar">
          <div className="button-bar__list">
            <Button orange><a href="/register">I'm an agent</a></Button>
            <Button orange><a href="/register">I'm a Broker</a></Button>
          </div>
        </div>
      </span>
      <span class="col-1-3">
        <div className="home-pricing__image">
          <img src={pricing_image?.url} alt="" />
        </div>
      </span>
    </section>
  );
};
export default Pricing;
