import { h } from "preact";
import Button from "../Button";
import "../../style/components/blocks/column_cta.css";

export default function ColumnCta(props) {
  const { primary, item, title, description, ctaLabel, ctaUrl, heading } =
    props;

  return (
    <div
      className={`column-cta ${
        primary ? "column-cta--primary" : "column-cta--secondary"
      } ${heading && "column-cta--has-heading"}`}
    >
      {heading && <span className="column-cta__heading">{heading}</span>}

      <div className="column-cta__content">
        <span className="column-cta__content-item">{item}</span>
        <h4 className="column-cta__content-title">{title}</h4>
        <p className="column-cta__content-description">{description}</p>
        {/* <Button > */}

        <a className="column-cta__button site-button" href={ctaUrl}>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="column-cta__content-icon"
          >
            <path
              d="M21.3333 15V19C21.3333 19.5304 21.1226 20.0391 20.7475 20.4142C20.3725 20.7893 19.8637 21 19.3333 21H5.33331C4.80288 21 4.29417 20.7893 3.9191 20.4142C3.54403 20.0391 3.33331 19.5304 3.33331 19V15"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.33331 10L12.3333 15L17.3333 10"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.3333 15V3"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {ctaLabel}
        </a>
        {/* </Button> */}
      </div>
    </div>
  );
}
