// Utility functions
import authWrapper from '../../Utilities/authGarage/authGarage';
import { route } from 'preact-router';
// App state
import { CoreState } from '../../Utilities/contextLibrary';
// Hooks
import { useContext, useEffect, useState } from 'preact/hooks';
// Telemetry functions
import LogRocket from 'logrocket';
import analytics from '../../Utilities/analytics';
// components
import Loader from '../../components/Loading/loading';
import api from '../../Utilities/API/api';
import PekinLoader from '../../components/PekinLoader';

const Auth = () => {
	const [ error, setError ] = useState({
		isError: false,
		message: null
	})

	const { coreState, coreDispatch } = useContext(CoreState);
	const { verifier, pkce } = coreState.handshake;
	const dest = window.localStorage.getItem('preact:authDest');
	useEffect(async() => {
		try {
			const tokens = await authWrapper.receive({ verifier, pkce });
			const userinfo = authWrapper.userinfo(tokens?.id_token);
			console.log("token: ", tokens);
			console.log("userinfo: ", userinfo);
			coreDispatch({ type: 'tokens', payload: tokens });
			coreDispatch({ type: 'userinfo', payload: userinfo });
			coreDispatch({ type: 'isAuth', payload: true });
			
			// Init user session recording with identity attached to session.
			LogRocket.identify(userinfo.email, {
				name: userinfo.given_name + ' ' + userinfo.family_name,
				uuid: userinfo.sub
			});
			// Initialize logging to GA for user session tracking
			analytics.identify(userinfo.email, {
				name: userinfo.given_name + ' ' + userinfo.family_name,
				uuid: userinfo.sub
			});
			
			const customer = await api.get(tokens.id_token, 'customer/', 'apiv5')
			const {response, status, headers} = customer
			console.log("Customer Response: ", response);
			if (status != 200) throw new Error('There was an issue with your request, please try again', );
			// if (status != 200) { alert(response.message); throw new Error(response.message)}
			coreDispatch({ type: 'customer', payload: response });
			coreDispatch({ type: 'role', payload: response.CLIENTSTATUS });
			if (dest && dest.includes('benefit')) return route(dest);
			if (response.CLIENTSTATUS == 'register') return route('/register')
			if (response.CLIENTSTATUS == 'locked') return route('/register/failed')
			return route('/dashboard');
	
		} catch (e) {
			coreDispatch({
				type: 'reset',
				payload: {
					isAuth: false,
					authPhase: 'receive',
					tokens: {}
				}
			})
			console.log('Error: ', e);
			alert(e);
			return route('/logout');
		}

	},['RedundancyGate'])


	return (
		<div style={{
            background: "white",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-around",
            width: "100%",
            height: "100vh",
          }}>
			<PekinLoader label="Verifying Account Details..." />
		</div>
	)
};

export default Auth;
