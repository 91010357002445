import React from "react";
import { h } from "preact";
import {
  useCallback,
  useRef,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "preact/hooks";
import { PlanSelectionContext } from "../PlanSelectionContext/PlanSelectionContext";
import OrderSummaryInput from "../OrderSummaryWidget/orderSummaryInput";
import InputField from "../InputField";
import SelectField from "../../components/SelectField";
import Button from "../Button";
import api from "../../Utilities/API/api";
import "../../style/components/blocks/order_summary_form.css";
import { CoreState } from "../../Utilities/contextLibrary";
import states from '../../Utilities/states';
import authWrapper from "../../Utilities/authGarage/authGarage";


export default function OrderSummaryForm(props) {
  // Props
  const {
    title = "Business Info",
    formRef,
    toggleIsReferralCode,
    isReferralCode,
  } = props;
  // Context
  const {
    planDetails,
    payByComission,
    setPayByComission,
    formData,
    setFormData,
    setButtonDisabled,
    isFormValid,
    setIsFormValid } =
  useContext(PlanSelectionContext);
  const { coreState, coreDispatch } = useContext(CoreState);
  const { userinfo, tokens } = coreState;
  const accessToken = tokens.access_token;
// Local State
  const [ writingNums, setWritingNums ] = useState(null);
  const [premiumLayout, setPremiumLayout] = useState(true);
  const [stateValid, setStateValid] = useState(true);
// Local Refs
  const local = Boolean(process.env.PREACT_APP_COGNITO_APP_URL.includes('localhost:8080'));
// Effects
  const handleChange = useCallback(() => {
    setPayByComission(!payByComission);
  }, [payByComission, setPayByComission]);

  const handleSubmission = useCallback(async(e) => {
    setButtonDisabled(true);
    e.preventDefault();
    // Set customer payload for fetch
    const customerPayload = {
      name: {
        first: formData.get('first_name') || userinfo.given_name  || "",
        last: formData.get('last_name') || userinfo.family_name  || ""
      },
      phone: formData.get('phone').replace(/\D/,"") || "",
    }
    // Set organization payload for fetch
    const organizationPayload = {
      name: formData.get('business_name') || "" ,
      address: {
          line1: formData.get('street_address') || "",
          line2: formData.get('street_address_2') || "",
        },
      city: formData.get('city') || "",
      state: formData.get('state') || "",
      zip: formData.get('zip') || ""
    }
    if (formData.get('writing_number'))  customerPayload.metadata = { writing_number: formData.get('writing_number') };

    console.log("Customer Payload: ", customerPayload);
    console.log("Organization Payload: ", organizationPayload);
    // Create customer first
    if (window.location.href.includes('register')) {
      try {
        // check if cognito provider is google
        if (userinfo.provider === 'Google') {
          const attributes = {
            given_name: formData.get('first_name'),
            family_name: formData.get('last_name')
          }
          console.log("Access Token: ",accessToken);
          authWrapper.updateAttributes({ accessToken, attributes }).then(res => {
            console.log('User Attr Update: ', res);
            authWrapper.refresh(coreState.tokens.refresh_token).then(res => {
              const { response, status } = res;
              if (status !== 200) {
                console.log('Error refreshing tokens: ', response);
                return;
              }
              const refreshResponse = {
                refresh_token: response.AuthenticationResult.refreshToken,
                access_token: response.AuthenticationResult.AccessToken,
                id_token: response.AuthenticationResult.IdToken,
                expiration: Math.floor(Date.now() / 1000) + response.AuthenticationResult.ExpiresIn
              }
              coreDispatch({type: 'tokens', payload: refreshResponse})
              coreDispatch({
                type: 'userinfo',
                payload: {
                  ...authWrapper.userinfo(refreshResponse.id_token),
                  ...attributes
                }
              });

            })
          }).catch((err) => {
            console.log("Error updating user attributes: ", err);
          })
        }
        const customer = await api.post(tokens.id_token, 'customer/', customerPayload, 'apiv5')
        const organization = await api.post(tokens.id_token, 'organization/', organizationPayload, 'apiv5')
        if ((customer.status != 200 || organization.status != 200)) {
          alert("Error creating your account. Please try again later.");
          return route('/register/failed');
        }
      } catch (error) {
        alert("Customer Creation Error: ", error.message);
        return;
      }
    } else {
      try {
        const customer = await api.put(tokens.id_token, 'customer/', customerPayload, 'apiv5')
        if (customer.status != 200) {
          alert("Error updating your account. Please try again later.");
          return route('/register/failed');
        }
        const organization = await api.put(tokens.id_token, 'organization/info', organizationPayload, 'apiv5')
        if ((customer.status != 200 || organization.status != 200)) {
          alert("Error updating your account. Please try again later.");
          return route('/register/failed');
        }
      } catch (error) {
        alert("Organization Creation Error: ", error.message);
        return;
      }

    }


    let checkoutQueryParams = new URLSearchParams();
    if (local) checkoutQueryParams.append('local', 'yes')
    checkoutQueryParams.append('plan', planDetails.type);
    if (isReferralCode) {
      console.log("Referral code: ", formData.get('referral_code'));
      checkoutQueryParams.append('refcode', formData.get('referral_code'));
    }
    console.log(checkoutQueryParams.toString())

    // Create payload for stripe checkout session creation

      const checkout = await api.get(tokens.id_token, `billing/checkout?${checkoutQueryParams}`, 'apiv5')
      if (!(checkout.status == 200)) return alert(checkout.response.message);
      window.location.assign(checkout.response.sessionURL);
      console.log("Checkout:", checkout);
  }, [planDetails, formData]);

  const checkIfValid = useCallback(() => {
    setFormData(new FormData(formRef.current));
    const inputEls = formRef.current.querySelectorAll("input, select");
    console.log("form data: ", inputEls);
    for (let i = 0; i < inputEls.length; i++) {
      const input = inputEls[i];
      const name = input.name;
      const value = input.value;
      const valid = input.validity.valid;
      if (!valid) {
        input.classList.add("input-field__input--invalid");
        console.log(name,": is not valid ", " ", value);
        if (name === "state") setStateValid(false);
        setIsFormValid(false);
        return
      }
      if (name === "state") setStateValid(true);
      input.classList.remove("input-field__input--invalid");
      console.log("valid ",name, " ", value);
      setIsFormValid(true);
    }
  }, [formRef]);

  useLayoutEffect(() => {
    if (['custom:aflacWritingNumber'] in userinfo) setWritingNums(userinfo['custom:aflacWritingNumber']);
    // setWritingNums(['WN333334'])
  }, [userinfo, isFormValid]);

  useEffect(() => {
    setFormData(new FormData(formRef.current));
    console.log("Writing Numbers: ", writingNums);
    if (planDetails.type === "full") {
      setPremiumLayout(true);
    } else {
      setPremiumLayout(false);
    }
    console.log("Plan Details: ",planDetails);
  }, [planDetails, payByComission, formRef, isFormValid]);

  return (
    <div className="order-summary-form" {...props}>
      <h2 className="order-summary-form__title">{title}</h2>

      <div className="order-summary-form__content">
        <form
          onSubmit={handleSubmission}
          className="order-summary-form__form"
          id="order-summary-form"
          ref={formRef}
        >
          <div className="order-summary-form__form-item span-12">
            <OrderSummaryInput
            checkIfValid={checkIfValid}
            required={true}
            label={"Business Name*"}
            type={"text"}
            name={"business_name"}
            minLength={1}
            maxLength={32}
            formRef={formRef}
            regexType={"text"}
            />
          </div>
            <div className="order-summary-form__form-item span-6">
              <OrderSummaryInput
                checkIfValid={checkIfValid}
                required={true}
                label={"First Name*"}
                type={"text"}
                name={"first_name"}
                minLength={1}
                maxLength={32}
                formRef={formRef}
                regexType={"text"}
                />
            </div>
            <div className="order-summary-form__form-item span-6">
              <OrderSummaryInput
                checkIfValid={checkIfValid}
                required={true}
                label={"Last Name*"}
                type={"text"}
                name={"last_name"}
                minLength={1}
                maxLength={32}
                formRef={formRef}
                regexType={"text"}
                />
            </div>
          {premiumLayout && (
            <>

              <div className="order-summary-form__form-item span-12">
                <InputField
                  required={true}
                  label={"Street Address*"}
                  type={"text"}
                  name={"street_address"}
                  />
              </div>

              <div className="order-summary-form__form-item span-12">
                <InputField
                  label={"Street Address (Line 2)*"}
                  type={"text"}
                  name={"street_address_2"}
                  />
              </div>

              <div className="order-summary-form__form-item span-6">
                <InputField label={"City*"} type={"text"} name={"city"} required={true} />
              </div>

              <div className="order-summary-form__form-item span-2">
                  <SelectField
                    label={"State*"}
                    name={"state"}
                    id={"state"}
                    minLength={2}
                    pattern="^[A-Z]{2}$"
                    onChange={checkIfValid}
                    isValid={stateValid}
                    options={states}
                    required={true}
                  />
              </div>
              <div className="order-summary-form__form-item span-4">
                <InputField label={"Zip*"} type="text" inputmode="numeric" pattern="^[0-9]{5}(?:-[0-9]{4})?$" name={"zip"} required={true} />
              </div>
            </>
          )}

          <div className="order-summary-form__form-item span-12">
            <OrderSummaryInput
              checkIfValid={checkIfValid}
              formRef={formRef}
              required={true}
              label={premiumLayout ? "Phone*" : "Phone (Optional)"}
              pattern="^[0-9]{10}$"
              minLength={1}
              maxLength={32}
              regexType={"phone"}
              type={"tel"}
              name={"phone"}
              validateLabel={`Sorry, that doesn't look like a valid phone number please enter digits only (i.e. "xxxxxxxxxx")`}
            />
          </div>

          {(premiumLayout && writingNums) && (
            <div className="order-summary-form__form-item span-12">
              <input
                type="checkbox"
                name="pbc"
                id="pbc"
                className="order-summary-form__form-checkbox"
                onChange={handleChange}
              />
              <label
                className="order-summary-form__form-checkbox-label"
                for="pbc"
              >
                I would like to pay by commission
              </label>
            </div>
          )}

          {payByComission && (
            <>
              {Array.isArray(writingNums) ? (
                <div className="order-summary-form__form-item span-12">
                  <SelectField
                    label={"Writing Number"}
                    options={writingNums}
                    name={"writing_number"}
                  />
                </div>
                ) : (
                  <div className="order-summary-form__form-item span-12">
                    <InputField
                      label={"Writing Number"}
                      value={writingNums}
                      type={"text"}
                      name={"writing_number"}
                      readonly={true}
                    />
                  </div>
                )}
            </>
          )}
          {(!payByComission && premiumLayout) && (
              <div className="order-summary-form__form-item span-12">
                <input
                  type="checkbox"
                  id="referral_code_checkbox"
                  name="referral_code_checkbox"
                  className="order-summary-form__form-checkbox"
                  onChange={toggleIsReferralCode}
                />
                <label
                  className="order-summary-form__form-checkbox-label"
                  for="referral_code_checkbox"
                >
                  Yes, I have a referral code
                </label>
              </div>
            )}

          {(isReferralCode && premiumLayout) && (
            <div className="order-summary-form__form-item span-6">
              <OrderSummaryInput
                checkIfValid={checkIfValid}
                label={"Referral Code"}
                id={"referral_code"}
                required={true}
                type={"text"}
                name={"referral_code"}
                formRef={formRef}
                validateLabel={`Sorry, that doesn't look like a valid referral code`}
                regexType={"text"}
                pattern={"[A-Za-z0-9]{12}"}
                />
            </div>
          )}

          {premiumLayout && (
            <div className="order-summary-form__form-item span-12">
              <input
                type="checkbox"
                name="billing_same"
                id="billing_same"
                className="order-summary-form__form-checkbox"
              />
              <label
                className="order-summary-form__form-checkbox-label"
                for="billing_name"
              >
                Billing address is same as business address.
              </label>
            </div>
          )}
          {!payByComission && (
            <div className="span-12">
              <input type="submit" id="submit" class="site-button" disabled={!isFormValid} value="Continue to Checkout">
                Continue to Checkout
              </input>
            </div>
          )
          }
        </form>
      </div>
    </div>
  );
}
