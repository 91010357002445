import { route } from 'preact-router';
import { useContext } from 'preact/hooks';
import Logout from '../../Containers/Auth/Logout/logout';
import { CoreState } from '../contextLibrary';
import authConfig from './authGarage.config';


class AuthGarageUtils {
	decodePayload = payload => {
		const cleanedPayload = payload.replace(/-/g, '+').replace(/_/g, '/');
		const decodedPayload = atob(cleanedPayload);

		const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
			const uriEncodedChar = ('00' + char.charCodeAt(0).toString(16)).slice(-2);
			return `${acc}%${uriEncodedChar}`;
		}, '');

		const jsonPayload = decodeURIComponent(uriEncodedPayload);

		return JSON.parse(jsonPayload);
	};

	getRandomString = () => {
		const randomItems = new Uint32Array(28);
		crypto.getRandomValues(randomItems);
		const binaryStringItems = randomItems.map(dec => `0${dec.toString(16).substr(-2)}`);
		return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
	};

	encryptStringWithSHA256 = async str => {
		const PROTOCOL = 'SHA-256';
		const textEncoder = new TextEncoder();
		const encodedData = textEncoder.encode(str);
		return crypto.subtle.digest(PROTOCOL, encodedData);
	};

	parseJSONFromJWT = token => {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return JSON.parse(jsonPayload);
	}

	hashToBase64url = arrayBuffer => {
		const items = new Uint8Array(arrayBuffer);
		const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '');
		const decodedHash = btoa(stringifiedArrayHash);

		const base64URL = decodedHash
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=+$/, '');

		return base64URL;
	};
}

class AuthGarage extends AuthGarageUtils {
	constructor(configObject) {
		super();
		this.config = configObject;
	};

	generate = async () => {

		const pkce = this.getRandomString();
		const verifier = this.getRandomString();

		const arrayHash = await this.encryptStringWithSHA256(verifier);
		const challenge = this.hashToBase64url(arrayHash);

		const newState = { ...JSON.parse(window.localStorage.getItem('preact:coreState')), ['handshake']: { pkce, verifier } }
		window.localStorage.setItem(
			'preact:coreState',
			JSON.stringify(newState)
		)
		return { pkce, verifier, challenge };
	}

	initiate = ({ pkce, challenge, provider }) => {
		window.location.href = '' +
			this.config.domain +
			'/oauth2/authorize' +
			'?response_type=code' +
			`&state=${pkce}` +
			`&client_id=${this.config.appClientId}` +
			`&redirect_uri=${this.config.redirectURI}` +
			`&identity_provider=${provider}` +
			`&scope=${this.config.scope}` +
			'&code_challenge_method=S256' +
			`&code_challenge=${challenge}`
			;
	}

	receive = async ({ verifier, pkce }) => {
		const urlParams = new URLSearchParams(window.location.search);

		// throw new Error()
		const responsePKCE = urlParams.get('state');
		const responseCode = urlParams.get('code');
		console.log('Response PKCE: ', responsePKCE);
		console.log('Request PKCE: ', pkce);

		if (responsePKCE !== pkce) {
			const errorMessage = 'State response did not match PKCE verification, please inform client';
			throw new Error(errorMessage);
		} else {
			const tokenDomain = '' +
				this.config.domain +
				'/oauth2/token?grant_type=authorization_code' +
				`&client_id=${this.config.appClientId}` +
				`&code_verifier=${verifier}` +
				`&redirect_uri=${this.config.redirectURI}` +
				`&code=${responseCode}`
				;

			const fetchOptions = {
				method: 'POST',
				headers: {
					'content-type': 'application/x-www-form-urlencoded'
				}
			};

			const tokenResponse = await fetch(tokenDomain, fetchOptions);
			const tokenJSON = await tokenResponse.json();

			tokenJSON.expiration = Math.floor(Date.now() / 1000) + tokenJSON.expires_in;
			delete tokenJSON.expires_in;

			return tokenJSON;
		}
	}

	userinfo = idToken => {
		const IDClaims = this.parseJSONFromJWT(idToken)

		if ('custom:aflacWritingNumber' in IDClaims) {
			const writingNum = IDClaims['custom:aflacWritingNumber'];
			const parseStringArray = string => {
				return /\]$/.test(string) ? (
					string
						.replace('[', '')
						.replace(']', '')
						.split(',')
						.map(num => num.trim())
				) : [string];
			};
			if (typeof writingNum === 'string') IDClaims['custom:aflacWritingNumber'] = parseStringArray(writingNum);
		};

		// return all attributes from IDClaims, as well as a clean list of providers for the user.
		return { ...IDClaims, provider: 'identities' in IDClaims ? IDClaims.identities[0].providerName : "Native" }
	}

	logout = pkce => {
		const logoutDomain =
			this.config.domain +
			'/logout?response_type=code' +
			`&client_id=${this.config.appClientId}` +
			`&logout_uri=${this.config.logoutURI}` +
			`&state=${pkce}` +
			`&scope=${this.config.scope}`
			;

		window.location.href = logoutDomain;
	}

	get currentTime() { return Math.floor(Date.now() / 1000); }


	parseAuthenticationResult = authResult => {
		return {
			refresh_token: authResult.RefreshToken,
			access_token: authResult.AccessToken,
			id_token: authResult.IdToken,
			expiration: Math.floor(Date.now() / 1000) + authResult.ExpiresIn
		}
	}

	// Kicks off a native sign up flow against the Cognito API
	signup = async ({ username, password, attributes }) => {
		return await this.handler(
			'SignUp',
			{
				Username: username,
				Password: password,
				UserAttributes: Object.keys(attributes).map(item => {
					return { Name: item, Value: attributes[item] }
				})
			},
			'POST'
		);

		// Exceptions:
	};

	// Kicks off a native sign up flow against the Cognito API
	confirmSignup = async ({ username, code }) => {
		return await this.handler(
			'ConfirmSignUp',
			{
				Username: username,
				ConfirmationCode: code
			},
			'POST'
		);

		// Exceptions:
	};
	// Kicks off a native authentication flow against the Cognito API
	login = async ({ username, password }) => {
		return await this.handler(
			'InitiateAuth',
			{
				AuthFlow: 'USER_PASSWORD_AUTH',
				AuthParameters: {
					USERNAME: username,
					PASSWORD: password
				}
			},
			'POST'
		);
	};

	// Kicks off a native authentication flow against the Cognito API
	changePassword = async ({ accessToken, previous, proposed }) => {
		return await this.handler(
			'ChangePassword',
			{
				AccessToken: accessToken,
				PreviousPassword: previous,
				ProposedPassword: proposed
			},
			'POST'
		);
	};

	// Kicks off a native authentication flow against the Cognito API
	verifyTokenSecret = async ({ session, code }) => {
		return await this.handler(
			'VerifySoftwareToken',
			{
				Session: session,
				UserCode: code
			},
			'POST'
		);
	};

	// Kicks off a native authentication flow against the Cognito API
	getTokenSecret = async ({ session }) => {
		return await this.handler(
			'AssociateSoftwareToken',
			{
				Session: session
			},
			'POST'
		);
	};

	challengeResponder = async (ChallengeName, responseObject, challengeSession) => {

		// Challenges we expect to receive:
		// MFA_SETUP
		// SOFTWARE_TOKEN_MFA

		return await this.handler(
			'RespondToAuthChallenge',
			{
				ChallengeName: ChallengeName,
				ChallengeResponses: responseObject,
				Session: challengeSession
			},
			'POST'
		)
	}


	// Signs out the user (specified by access token) from all active sessions
	// logout = async token => {
	//     return await this.handler(
	//         'GlobalSignOut',
	//         {
	//             AccessToken: token
	//         },
	//         'POST'
	//     )
	// }

	// Returns updated tokens given a users refresh token.
	// Caching these values is the application's responsibility...should it be?
	refresh = async refreshToken => {

		// Consider implementing status/header checks for improved error handling
		const { response, status, headers } = await this.handler(
			'InitiateAuth',
			{
				AuthFlow: 'REFRESH_TOKEN',
				AuthParameters: {
					REFRESH_TOKEN: refreshToken
				}
			},
			'POST'
		);
		return { response, status }
	}

	// Returns attributes for the user from the cognito API
	updateAttributes = async ({ accessToken, attributes }) => {
		const attributeList = Object.keys(attributes).map(item => {
			return { Name: item, Value: attributes[item] }
		})
		return await this.handler(
			'UpdateUserAttributes',
			{
				"AccessToken": accessToken,
				"UserAttributes": attributeList
			},
			'POST'
		);
	}



	handler = async (action, request, method) => {

		// inject cognito client info into the request
		request.ClientId = this.config.appClientId

		const constructHeaders = iterator => {
			const headers = {}
			for (let pair of iterator) {
				headers[pair[0]] = pair[1]
			}
			return headers
		};

		let response = await fetch('https://cognito-idp.us-east-1.amazonaws.com/', {
			method: method,
			headers: {
				'x-amz-target': `AWSCognitoIdentityProviderService.${action}`,
				'content-type': 'application/x-amz-json-1.1',
			},
			body: (method == 'GET') ? null : JSON.stringify(request)
		});

		const headers = constructHeaders(response.headers);
		const status = response.status;

		response = await response.json();
		return { status, headers, response };
	}
}


const authWrapper = new AuthGarage(authConfig);
export default authWrapper;