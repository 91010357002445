import { createRef } from "preact";
import { route } from "preact-router";

import { useRef, useLayoutEffect, useContext, useState} from 'preact/hooks';
import nativeAuth from '../../Utilities/authGarage/authGarage';
import { CoreState } from "../../Utilities/contextLibrary";

const AuthenticatorHelp = () => {
    return (
        <>
            Scan the QR Code from your preffered MFA app 
            {/* <a href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid">Help</a> */}
        </>
    )
}

export default ({ context }) => {
    const  {coreState, coreDispatch} = useContext(CoreState);
    const {authState, setAuthState} = useContext(context);
    const dest = window.localStorage.getItem('preact:authDest');

    const codeRef = createRef(null)
    const qrcode = useRef(null)
    const localSession = createRef(null)

    const [prompt, setPrompt] = useState({
        title: 'Connect to Authenticator App',
        content: <AuthenticatorHelp />,
        isError: false
    })

    useLayoutEffect(() => {
        if (qrcode.current) {
            // console.log('init QR')
            nativeAuth.getTokenSecret({ session: authState.user.session }).then(res => {
                localSession.current = res.response.Session;
                const uri = `otpauth://totp/AflacCyberProtection?secret=${res.response.SecretCode}&issuer=${authState.user.email}`;
                new QRCode(qrcode.current, uri);
            });
            return (() => qrcode.current = null);
        }
    }, [qrcode])

    const handleSubmit = async e => {
        e.preventDefault();

        // console.log('submitting')
        const code = codeRef.current.value;

        const { status, headers, response } = await nativeAuth.verifyTokenSecret({
            session: localSession.current,
            code: code
        });

        if (status != 200) {
            setPrompt({...prompt,
                content: headers['x-amzn-errormessage'],
                // content: (() => {
                //     switch () {
                //         case 'MFA_SETUP': return 'mfa-setup'
                //         case 'SOFTWARE_TOKEN_MFA': return 'token-mfa'
                //     }
                // })(),
                isError: true
            })
            return
        }


        // HANDLE EXCEPTIONS
        const TOTPMFAConfigureResponse = await nativeAuth.challengeResponder(
            'MFA_SETUP',
            {
                'USERNAME': authState.user.email
            },
            response.Session
        );

        const tokens = nativeAuth.parseAuthenticationResult(TOTPMFAConfigureResponse.response.AuthenticationResult);
        
        coreDispatch({ type: 'tokens', payload: tokens });
        route(dest, true);
    }

    return (
        <section class="padding-large">
            <div class="column margin-x-auto margin-y-1 col-1-3">
                <h1 class="align-center padding-y-1 display1 -extra-lite">{prompt.title}</h1>
                <div class="align-center margin-y-1" ref={qrcode}></div>
                <p class={`${prompt.isError} align-center -heavy`}>{prompt.isError ? 'Error: ': null}{prompt.content}</p>
                <form onSubmit={handleSubmit}>
                    <div class="input_container">
                        <input 
                            ref={codeRef}
                            type="tel"
                            autofocus 
                            id="totp" />
                        <label for="totp">Token</label>
                    </div>
                    <div class="row margin-y-1">
                        <button class="back" style="background: white; border: none; width: 50%; font-size: 18px; font-weight: 600;" type="back">Back</button>
                        <button style="background: #01A7E1; border: none; width: 50%; color: white; padding: 18px; font-size: 18px; font-weight: 600;" type="submit">Next</button>
                    </div>
                </form>
            </div>
            <div class="-line" />
            <div class="row justify-center">
                <span class="col-1-3" style="width: fit-content; margin: 1rem;">
                    <h2 class="display2 -extra-lite">How registration works</h2>
                    <ol class="align-left list-circle">
                        <li>Download an authenticator app <br/>to your mobile device.</li>
                        <li>Open the app and scan<br/>the QR code above.</li>
                        <li>The app will give you a numeric code.<br/> Enter that code above.</li>
                        <li>Anytime you log in, you'll need to open<br/> your authenticator and enter your code.</li>
                    </ol>
                </span>
                <span class="column col-1-3 bg-grey" style="padding: 64px; margin: 1rem; height: fit-content;"> 
                    <h3 class="display3b align-center -lite">Recommended</h3>
                    <div class="row margin-top-2">
                        <img src="/assets/images/google_authenticator.png" class="-icon margin-x-1" />
                        <span>
                            <h4 style="margin: 0px">Google Authenticator App</h4>
                            <p>Download on</p>
                            <a href="" >
                                <img src="/assets/images/icons/apple_icon.png" class="fa" aria-hidden="true" />
                            </a>
                            <a href="" >
                                <img src="/assets/images/icons/google_play.png" class="fa" aria-hidden="true" />
                            </a>
                        </span>
                    </div>
                    <div class="row margin-top-2">
                        <img src="/assets/images/microsoft_authenticator.png" class="-icon margin-x-1" />
                        <span>
                            <h4 style="margin: 0px">Microsoft Authenticator App</h4>
                            <p>Download on</p>
                            <a href="" >
                                <img src="/assets/images/icons/apple_icon.png" class="fa" aria-hidden="true" />
                            </a>
                            <a href="" >
                                <img src="/assets/images/icons/google_play.png" class="fa" aria-hidden="true" />
                            </a>
                        </span>
                    </div>
                </span>
            </div>
        </section>
    )
}