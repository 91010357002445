/* eslint-disable no-unused-vars */
import { h } from "preact";

import { useState, useContext, useEffect, useRef, useCallback } from "preact/hooks";

import { PlanSelectionContext, PlanSelectionProvider } from "../PlanSelectionContext/PlanSelectionContext";

import PlanSelection from "./PlanSelection";
import OrderSummaryWidget from "../OrderSummaryWidget";
import OrderSummaryForm from "../OrderSummaryForm";

import "../../style/components/blocks/plan_selection_page.css";
import { route } from "preact-router";
import api from "../../Utilities/API/api";
import { CoreState } from "../../Utilities/contextLibrary";
import { prismicClient } from "../../Utilities/prismic/prismicClient";

export default function PlanSelectionPage(props) {
  const { coreState, coreDispatch } = useContext(CoreState);
  const { userinfo, tokens } = coreState;
  const { upgrade = false} = props;
  const { planDetails, payByComission, setPayByCommission } =
  useContext(PlanSelectionContext);
  const local = Boolean(process.env.PREACT_APP_COGNITO_APP_URL.includes('localhost:8080'));
  // Local State
  const [isReferralCode, setIsReferralCode] = useState(false);
  const [ showWidget, setShowWidget ] = useState(false);
  const [ doc, setDocData ] = useState(null);
  prismicClient('identity_and_purchasing_flows', 'registration').then(res => {
    setDocData(res.data)
  });

  // Dom Refs
  const formRef = useRef(null);
  useEffect(() => {
    if(doc) {
      console.log('Doc: ', doc);
    }
    },[doc]);

  useEffect(() => {
    console.log("Prismic Data: ", doc);
    console.log(payByComission);
    setShowWidget(payByComission);
  },[payByComission, setPayByCommission])

  return (
    <div className="plan-selection-page" {...props}>
      <PlanSelectionProvider>
        <section className="plan-selection-page__main">
          <div class="plan-selection-plan__selection">
          { upgrade && <h2 className="plan-selection-page__title margin-y-1">Upgrade Your Plan</h2>}
              <PlanSelection
                title={"Select Aflac Cyber Protection Tier"}
                upgrade={upgrade}
                aflacCyberLabel={"Premium"}
                itemsAflac={
                  doc && doc.plan[0].plan_feature_list.map(item => {
                    return {
                      label: item.text
                    }
                  })
                }
              />
            <div className="plan-selection-page__form">
              <OrderSummaryForm
                formRef={formRef}
                toggleIsReferralCode={() => setIsReferralCode(!isReferralCode)}
                isReferralCode={isReferralCode}
                />
            </div>
          </div>

          <div className="plan-selection-plan__summary">
            <OrderSummaryWidget
              title={"Order summary"}
              protectionPlanLabel={"Cyber Protetion (for 3 devices)"}
              protectionPlanTotal={14.99}
              deviceCountLabel={"Computers ($19.99/per computer after 3)"}
              deviceCountTotal={3}
              monthlyTotalLabel={"Monthly total"}
              monthlyTotal={14.99}
              todaysTotalLabel={"Today's total"}
              todaysTotal={14.99}
              termsLabel={`I agree with the <a href="/terms" target="_blank"> Terms and Conditions</a>`}
              authorizedLabel={
                "I am authorized to make this purchase on behalf of the <organization name>"
              }
              offersLabel={
                "I want to see more about a special offer for Coalition™ Cyber Insurance after checkout"
              }
              couponLabel={"I have a referral code"}
              isReferralCode={isReferralCode}
              // isReferralCodeValid={isReferralCodeValid}
            />
          </div>
        </section>
      </PlanSelectionProvider>
    </div>
  );
}
