/* eslint-disable no-unused-vars */
import { h } from "preact";

import { useState, useCallback, useContext, useEffect } from "preact/hooks";

import Button from "../Button";
import PlanSelectionItems from "./PlanSelectionItems";
import { PlanSelectionContext } from "../PlanSelectionContext/PlanSelectionContext";

import "../../style/components/blocks/plan_selection.css";

export default function PlanSelection(props) {
  const {
    title,
    aflacCyberLabel = "Aflac Cyber Premium Protection",
    itemsAflac,
    upgrade
  } = props;

  const { planDetails, setPlanDetails, payByComission, setPayByComission } =
    useContext(PlanSelectionContext);
    console.log("Plan Details: ", planDetails);

  const [active, setActive] = useState(0);

  const handleClick = useCallback(
    (e) => {
      // eslint-disable-next-line radix
      const index = parseInt(e.target.closest("button").id, 0);
      if (index !== active) {
        setActive(index);
      }

      if (index === 0) {
        setPlanDetails({
          type: "full",
          ctaLabel: "Continue to Checkout",
        });
      }
    },
    [active, payByComission, planDetails]
  );

  return (
    <div className="plan-selection" {...props}>
      {title && !upgrade && <h3 className="plan-selection__title">{title}</h3>}
      {
        !upgrade &&
          <nav className="plan-selection__button-bar">
            <Button
              id="0"
              className="plan-selection__button plan-selection__button--aflac"
              label={aflacCyberLabel}
              onClick={handleClick}
              active={active === 0}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`plan-selection__button-icon ${
                  active === 0 && "is-active"
                }`}
              >
                <path
                  d="M36.6667 18.4667V20C36.6647 23.594 35.5009 27.0911 33.3489 29.9697C31.197 32.8483 28.1722 34.9541 24.7256 35.9732C21.2791 36.9922 17.5955 36.8698 14.2242 35.6243C10.8528 34.3788 7.97447 32.0768 6.01831 29.0618C4.06215 26.0467 3.13303 22.4801 3.3695 18.8939C3.60598 15.3076 4.99539 11.8939 7.33051 9.16179C9.66563 6.4297 12.8214 4.52564 16.327 3.73357C19.8327 2.94151 23.5005 3.30389 26.7834 4.76667"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.6667 6.66669L20 23.35L15 18.35"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </nav>
      }

      <div className="plan-selection__lists">
        <PlanSelectionItems
          items={itemsAflac}
          className={`plan-selection__list plan-selection__list--aflac ${
            active === 0 && "is-active"
          }`}
          active={active === 0}
        />
      </div>
    </div>
  );
}
