import {h} from 'preact';
import { route } from 'preact-router';

const TeamTile = ({member}) => {
    const routeToUser = () => route(`/dashboard/team/manage?id=${member.CLIENTUUID}`);
    // console.log('member: ', member);
    return (
        <div onClick={member.type === 'pending' ? null : routeToUser} class="tile padding-medium" style={member.alertNum > 0 ? 'border: 0px solid var(--brand-offwhite); background-color: var(--brand-light-orange);' : 'border: 1px solid var(--brand-offwhite);'}>
            <span class="user-icon">
                <div class="display1 -extra-lite flex justify-center round bg-lighter-blue badge" style={`--width: 96px; --badge: url('/assets/images/icons/icon-badge-${member.alertNum == 0 ? 'gold' : member.alertNum == 1 ? 'silver' : 'bronze'}.svg'); align-items: center; color: var(--brand-blue);`}>
                    <span style="position: relative; left: 14%;">{member.name.first?.split("")[0] || 'L' }{member.name.last?.split("")[0] || 'E'}</span>
                </div>
            </span>
            <span class="user-info">
                <h3 class="display3a align-center">{member.name.first || member.email} {member.name.last}</h3>
                <p>{member.type.charAt(0).toUpperCase() + member.type.slice(1)}</p>
                { !['pending'].includes(member.type) &&
                    <div class="row margin-y-1 space-around margin-x-auto">
                        <span class="display5" style="color: var(--brand-blue); margin-right: 8px;">
                            <img src="/assets/images/icons/icon-devices.svg" />
                            {member.deviceNum} {member.deviceNum == 1 ? 'device' : 'devices' || 'devices'}
                        </span>
                        <span class="display5" style={`color: ${member.alertNum > 0 ? 'var(--brand-orange)' : 'var(--brand-green)'}; margin-right: 8px;`}>
                            <img src={member.alertNum > 0 ? '/assets/images/icons/alert-circle.svg' : '/assets/images/icons/icon-check-circle.svg'} />
                            {member.alertNum} {member.alertNum == 1 ? 'issue' : 'issues' || 'issues'}
                        </span>
                    </div>
                }
            </span>
            {member.type !== 'pending' && <a href="#" onClick={routeToUser} class="-view" style="color: var(--brand-charcoal); font-weight: 600; font-size: 14px;">View <img src="/assets/images/icons/icon-arrow-right.svg" /></a>}
        </div>
    )
}

export default TeamTile;