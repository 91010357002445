import { useEffect, useState, useContext } from 'preact/hooks';
import api from '../../../Utilities/API/api';
import { CoreState } from '../../../Utilities/contextLibrary';
import TeamTile from '../../../components/Team/team_tile';
import Loader from '../../../components/Loading/loading';


const Team = () => {
    const { coreState } = useContext(CoreState);
    const [ team, setTeam ] = useState(null);
    const [ subscription, setSubscription ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    
    useEffect(() => {
        api.get(coreState.tokens.id_token, 'customer/subscription', 'apiv5').then(res => {
            // console.log('subscription: ', res.response);
            setSubscription(res.response)
        })
        api.get(coreState.tokens.id_token, 'employee/all', 'apiv5').then(res => {
            console.log('res: ', res);
            if (res.response.length > 0) {
                setTeam(res.response);
            } 
        })
        .finally(() => {
            setLoading(false);
        });
    }, ['redundancyGate'])

    if (loading) return <Loader />;
    return (
        <section class="bg-white padding-large">
            <div class="row space-between margin-y-1">
                <h2 class="display2 -extra-lite">Manage Team</h2>
                <span class="add-team-member">
                    <a href='/dashboard/team/add' class="button -blue"> + Add Team Member</a>
                    <p class="-lite margin-y-1" style="font-size: 14px;">Unlimited Users. Your account is currently using {subscription?.consumption} of {subscription?.licenses} <a href="/dashboard/account" class="-link blue">licenses</a> on your subscription.</p>
                </span>    
            </div>
            {
                coreState.role.includes('owner') && 
                <>
                    <div class="margin-y-2">
                        <h3 class="display3a -lite margin-y-1">Owner</h3>
                        <div class="tile-container">
                            { team?.map(member => member.type == 'owner' ? <TeamTile member={member} /> : null )}

                        </div>
                    </div>
                </>
            }
            <div class="margin-y-2">
                <h3 class="display3a -lite margin-y-1">Admins</h3>
                <div class="tile-container">
                    { team?.map(member => member.type == 'admin' && <TeamTile member={member} /> )}

                </div>
            </div>
            <div class="margin-y-2">
                <h3 class="display3a -lite margin-y-1">Team Members</h3>
                <div class="tile-container">
                    { team?.map(member => member.type == 'employee' && <TeamTile key={member} member={member} />)}
                </div>
            </div>
            <div class="margin-y-2">
                <h3 class="display3a -lite margin-y-1">Pending Invites</h3>
                <div class="tile-container">
                    { team?.map(member => member.type == 'pending' && <TeamTile key={member} member={member} />)}
                </div>
            </div>
            <div class="margin-y-2">
                <h3 class="display3a -lite margin-y-1">Locked Employees</h3>
                <div class="tile-container">
                    { team?.map(member => member.type == 'locked' && <TeamTile key={member} member={member} />)}
                </div>
            </div>
        </section>
    )
}

export default Team;