import { h } from "preact";

import { useCallback, useRef, useState } from "preact/hooks";

import "../../style/components/blocks/pekin_loader.css";

import loaderVideo from "../../assets/videos/Aflac-In-Progress-320w.mp4";
import loaderPoster from "../../assets/images/loader-dots-poster@2x.png";

export default function PekinLoader(props) {
  const {
    videoSrc = loaderVideo,
    posterSrc = loaderPoster,
    label = "We're working on getting things setup.",
  } = props;

  const videoRef = useRef(null);

  return (
    <div className="pekin-loader">
      <video
        autoPlay
        muted={true}
        loop
        playsInline
        poster={posterSrc}
        ref={videoRef}
        className="pekin-loader__video"
      >
        <source src={videoSrc} type="video/mp4" />
      </video>

      {label && <span className="pekin-loader__label">{label}</span>}
    </div>
  );
}