import { createRef } from "preact";
import { useContext, useState } from "preact/hooks";
import nativeAuth from '../../Utilities/authGarage/authGarage'

export default ({ context }) => {
    const {authState, setAuthState} = useContext(context)

    const codeRef = createRef(null)
    const [prompt, setPrompt] = useState({
        title: 'Confirm Email',
        content: 'Confirm your account by using the 6-digit code that was sent to your email',
        isError: false
    })
    const goBack = () => {
        setAuthState({...authState,
        stage: 'sign-up'})
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const signupResponse = await nativeAuth.confirmSignup({
            username: authState.user.email,
            code: codeRef.current.value
        })


        const { status, headers, response } = await nativeAuth.login({
            username: authState.user.email,
            password: authState.user.pass
        })

        if (status != 200) {
            setPrompt({...prompt,
                content: headers['x-amzn-errormessage'],
                isError: true
            })
            return
        }

        setAuthState({...authState,
            stage: (() => {
                switch (response.ChallengeName) {
                    case 'MFA_SETUP': return 'mfa-setup'
                    case 'SOFTWARE_TOKEN_MFA': return 'token-mfa'
                }
            })(),
            user: {
                session: response.Session,
                email: authState.user.email
            }
        })
    } 
    return (
        <div class="padding-medium">
            <div class="column margin-x-auto margin-y-1 col-1-3">
                <h1 class="align-center padding-y-1 display1 -extra-lite">{prompt.title}</h1>
                <p class={`${prompt.isError} align-center -extra-lite`}>{prompt.isError ? 'Error: ': null}{prompt.content}</p>
                <form onSubmit={handleSubmit}>
                    <div class="input_container">
                        <input 
                            ref={codeRef}
                            type="tel"
                            autofocus 
                            id="email_code" />
                        <label for="email_code">Confirmation Code</label>
                    </div>
                    <div class="row margin-y-1">
                        <button class="back" style="background: white; border: none; width: 50%; font-size: 18px; font-weight: 600;" onClick={goBack} type="back">Back</button>
                        <button style="background: #01A7E1; border: none; width: 50%; color: white; padding: 18px; font-size: 18px; font-weight: 600;" type="submit">Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
}