// import { h, Component } from "preact";
import { useEffect, useState, useContext } from "preact/hooks";
import BannerCta from "../../components/BannerCta";
import ColumnCta from "../../components/ColumnCta";
import { prismicClient } from "../../Utilities/prismic/prismicClient";
import { CoreState } from "../../Utilities/contextLibrary";

import "../../style/components/containers/guides.css";

const Guides = () => {
  const [doc, setDocData] = useState(null);
  const { coreState } = useContext(CoreState);
  useEffect(() => {
    prismicClient("cybersecurity-guide", "cybersecurity-guide").then((res) => {
      console.log("prismicData: ", res);
      if (res) {
        setDocData(res.data);
      } else {
        console.warn(
          "Guide not found. Make sure it exists in your Prismic repository"
        );
      }
    });
  }, []);
  if (!doc) return null;
  return (
    <div class="bg-white  padding-large">
      <div className="security-guides">
        <div className="security-guides__content">
          <div className="security-guides__cards">
            <div className="security-guides__title">
              <h1 class="display1 -extra-lite">Security Guides</h1>
            </div>
            <div className="security-guides__cards-list">
              <ColumnCta
                item={1}
                primary
                title={doc.column_cta[0].title[0].text}
                description={doc.column_cta[0].description[0].text}
                ctaLabel={doc.column_cta[0].cta_label[0].text}
                ctaUrl={`${coreState.customer.access.prefix}Cyber_Security_Brief.pdf${coreState.customer.access.suffix}`}
                heading={
                  doc.column_cta[0].heading.length > 0
                    ? doc.column_cta[0].heading[0].text
                    : null
                }
              />
              <ColumnCta
                item={2}
                title={doc.column_cta[1].title[0].text}
                description={doc.column_cta[1].description[0].text}
                ctaLabel={doc.column_cta[1].cta_label[0].text}
                ctaUrl={`${coreState.customer.access.prefix}Cyber_Security_Guide.pdf${coreState.customer.access.suffix}`}
                heading={
                  doc.column_cta[1].heading.length > 0
                    ? doc.column_cta[2].heading[0].text
                    : null
                }
              />
              <ColumnCta
                item={3}
                title={doc.column_cta[2].title[0].text}
                description={doc.column_cta[2].description[0].text}
                ctaLabel={doc.column_cta[2].cta_label[0].text}
                ctaUrl={`${coreState.customer.access.prefix}SecurityChecklist.pdf${coreState.customer.access.suffix}`}
                heading={
                  doc.column_cta[2].heading.length > 0
                    ? doc.column_cta[2].heading[0].text
                    : null
                }
              />
            </div>
          </div>
          <>
            {
              coreState.role.includes('basics') &&
              <div className="security-guides__banner">
                    <BannerCta
                      image={doc.banner_image.url}
                      title={doc.banner_title[0].text}
                      label={doc.banner_label[0].text}
                      url={"/dashboard/upgrade"}
                    />
              </div>
            }
          </>
        </div>
      </div>
    </div>
  );
};

export default Guides;
