/* eslint-disable no-unused-vars */
import { h } from "preact";
import "../../style/components/blocks/plan_selection_items.css";

export default function PlanSelectionItems(props) {
  const { items } = props;

  return (
    <ul className="plan-selection-items" {...props}>
      {items &&
        items.map((item, index) => (
          <li key={index} className="plan-selection-items__item">
            <svg
              width="17"
              height="13"
              viewBox="0 0 17 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="plan-selection-items__item-icon"
            >
              <path
                d="M1.07129 6.14286L5.92843 11L15.9284 1"
                stroke="currentColor"
                stroke-width="2"
              />
            </svg>

            <span className="plan-selection-items__item-label">
              {item.label}
            </span>
          </li>
        ))}
    </ul>
  );
}
