import { h } from 'preact';
import { useState, useContext } from 'preact/hooks';
import Prismic from '@prismicio/client';

// Prismic API
const apiEndpoint = 'https://aflaccyberprotection.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint);


const prismicClient = async (type, page) => {
   const client = await Client.getByUID( type, page );
//    console.log('prismicClient: ', client);
   return client;
};
const prismicBlogs = async (type) => (
    await Client.query(
        Prismic.Predicates.at('document.type', type),
        { pageSize: 8, page: 1, orderings: '[my.blog_post.post_date]' }
    )
);
export { prismicClient, prismicBlogs };
// primsmic api end
