import { h } from "preact";
import "../../style/components/blocks/failed_contact_us.css";

import SupportDuck from "../../assets/images/bg-aflac-duck@2x.png";

import { prismicClient } from "../../Utilities/prismic/prismicClient";

const FailedContactUs = () => {
  const { data } = prismicClient('identity_and_purchasing_flows',  'registration').then((res) => { return res; });
  console.log("prismicData Failed page: ",data);
  return (
    <section class="failed-contact-us">
      <div class="failed-contact-us__text">
        <h2 class="failed-contact-us__text-title">We’re here to help.</h2>
        <p class="failed-contact-us__text-copy">
          {data?.failed_contact_us_copy?.[0]?.text}
        </p>
        <a
          class="failed-contact-us__text-link"
          href="mailto:cyberprotection@aflac.com"
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.83464 3.33301H17.168C18.0846 3.33301 18.8346 4.08301 18.8346 4.99967V14.9997C18.8346 15.9163 18.0846 16.6663 17.168 16.6663H3.83464C2.91797 16.6663 2.16797 15.9163 2.16797 14.9997V4.99967C2.16797 4.08301 2.91797 3.33301 3.83464 3.33301Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.8346 5L10.5013 10.8333L2.16797 5"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>cyberprotection@aflac.com</span>
        </a>
      </div>

      <div class="failed-contact-us__image">
        <img src={SupportDuck} alt="Support desk duck with headset" />
      </div>
    </section>
  );
};

export default FailedContactUs;
