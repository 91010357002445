/* eslint-disable no-unused-vars */
import { h } from "preact";
import Button from "../Button";
import "../../style/components/blocks/banner_cta.css";
import { useEffect, useContext, useState } from "preact/hooks";
import { prismicClient } from "../../Utilities/prismic/prismicClient";
import { CoreState } from "../../Utilities/contextLibrary";

export default function BannerCta(props) {
  const { image, title, label, url } = props;

  return (
    <a href={url} className="banner-cta" aria-label={label}>
      <figure className="banner-cta__figure">
        {image && (
          <div className="banner-cta__image">
            <img src={image} alt="" role="presentation" />
          </div>
        )}

        <figcaption className="banner-cta__figcaption">
          {title && <h3 className="banner-cta__title">{title}</h3>}

          <Button orange label={label} className="banner-cta__button" />
        </figcaption>
      </figure>
    </a>
  );
}
