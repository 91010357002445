import { h } from 'preact';
import { prismicClient } from '../../Utilities/prismic/prismicClient';
import { useEffect, useState } from 'preact/hooks';
import {Date, Link, RichText} from 'prismic-reactjs';

const BlogPost = ({ uid }) => {
    const [current, setCurrent] = useState(false);
    useEffect(() => {
        prismicClient('blog_post', uid).then(res => {
            setCurrent(res.data)
            console.log('res: ', res);
        })
    }, ['redunancyGate'])
    
    console.log('current: ', current);

    return (
        current ? (
            <article class={`post`}>
                <h1>
                    {current.post_title[0].text}
                </h1>
                <img src={current.post_image.url} />
                <RichText 
                    render={current.post_content}
                />
            </article>
        ) : (<></>)
    )
};
export default BlogPost;
