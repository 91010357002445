import { h } from 'preact';

const Block = ({ content }) => {
	return (
        <section id="solutions" class={`block solutions`}>
            <h2 class={`-title display1`}>{content.blocks[0].cta_title[0].text}</h2>
            <p class="summary body">{content.blocks[0].cta_copy[0].text}</p>
            <p class="action">
                {content.blocks[0].cta_link_label ? (<a class={`button -white`} href={"/register"}>{content.blocks[0].cta_link_label[0].text}</a>) : null}
            </p>
        </section>
	)
};

export default Block;
