import { useContext, useEffect, useLayoutEffect, useRef, useState } from "preact/hooks"
import { CoreState } from '../../Utilities/contextLibrary';
import api from "../../Utilities/API/api";
import { route } from "preact-router";

const EmployeeValidation = () => {
    const { coreState, coreDispatch } = useContext(CoreState);
    // const { validationState, setValidationState } = useContext(context);
    const [ helperText, setHelperText ] = useState("Please enter your organization's invite code below:");

    const params = new URLSearchParams(window.location.search)
    const code = params.get('otic')
    const token = coreState.tokens.id_token

    const codeRef = useRef(null);

    const submitLogic = async (value = null) => {

        const { status, response, headers } = await api.get(token, `validate/employee/invite/${value ? value : codeRef.current.value}`, 'apiv5')
        
        const isNotLocked = Boolean(response.CLIENTTYPE != 'locked')
        const isReadyToRegister = Boolean(response.CLIENTSTATUS === 'register')

        if (isNotLocked && isReadyToRegister) {
            coreDispatch({type: "role", payload: response.CLIENTTYPE})
            coreDispatch({type: "customer", payload: {...coreState.customer, CLIENTSTATUS: response.CLIENTSTATUS}})
            route('/employee/register/confirm')
            return
        }
    
        if (status != 200) {
            setHelperText('Invalid Invite')
            return
        }
    }

    useLayoutEffect(async () => {
        console.log('invite code in params: ', code);
        if (code) { await submitLogic(code) }
    },[code])
 

    const handleSubmit = async e => {
        e.preventDefault();
        await submitLogic();
    }

    return (
        <section class="bg-white margin-auto">
            <section class="padding-large">
                <div class="column margin-x-auto margin-y-1 col-1-3">
                    <h1 class="align-center padding-y-1 display1 -extra-lite">Register With Organization</h1>
                    <p class="align-center padding-medium display4-lite">{helperText}</p>
                    <form onSubmit={handleSubmit}>
                        <div class="input_container">
                            <input 
                                ref={codeRef}
                                type="tel"
                                autofocus 
                                id="otic" />
                            <label for="otic">Invite Code</label>
                        </div>
                        <div class="row margin-y-1">
                            <button style="background: #01A7E1; border: none; width: 100%; color: white; padding: 18px; font-size: 18px; font-weight: 600;" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </section>
        </section>
    )
};

export default EmployeeValidation;