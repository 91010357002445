import { h } from "preact";
import { useRef, useState } from "preact/hooks";
import api from "../../Utilities/API/api";

import { CancelSubscription } from "./CancelSubscription";
import { UpdateSubscription } from "./UpdateSubscription";

export const AccountOverview = ({ props }) => {
  const { subscription, employee, customer, devices, coreState } = props;

  const formatDate = (date) => {
    if (!date) return "";
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(new Date(date * 1000));
  };
  const formatBalance = (balance) => {
    return (balance / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const [cancelModal, setCancelModal] = useState();
  const [subModal, setSubModal] = useState();
  const deviceNum = useRef(null);

  const toggleSubModal = (e) => {
    setSubModal((prev) => !prev);
  };
  const toggleCancelModal = () => {
    setCancelModal((prev) => !prev);
  };
  const updateDevices = (e) => {
    e.preventDefault();
    api
      .put(coreState.tokens.id_token, "customer/subscription", {
        licenses: deviceNum.current.valueAsNumber.toString(),
      })
      .then((res) => {
        console.log("Device update response: ", res.response);
        window.location.reload();
      });
  };

  return (
    <section>
      <h2 class="display2 -extra-lite">Account Overview</h2>
      <div class="bg-light-green margin-y-1 padding-48 align-center">
        <h3 class="display3b -extra-lite">Balance</h3>
        <h1 class={`display1 -extra-lite`} style="color: var(--brand-green);">
          {formatBalance(subscription?.balance || 0)}
        </h1>
        {/* Add balance up to date logic here */}
        <h4
          class="display4 -extra-lite margin-x-auto"
          style="color: var(--brand-grey);"
        >
          <img src="/assets/images/icons/icon-check.svg" />
          Paid on {formatDate(subscription?.next_assesment_at)} Everything Looks
          good!
        </h4>
      </div>
      <div class="margin-y-1 row space-between">
        <span class="col-2-4">
          <div class="margin-y-2">
            <h3 class="display3a -lite margin-y-1">Billing</h3>
            <h6 style="margin: 0px;">Next Billing Date</h6>
            <h4 class="display4a -extra-lite">
              {formatDate(subscription?.next_assesment_at)}
            </h4>
            <span class="margin-y-1" style="padding: 24px 0px;">
              <a
                class="link -blue margin-right-2"
                href="/dashboard/account/billing"
              >
                Billing History
              </a>
              <a
                class="link -blue margin-right-2"
                href="/dashboard/account/billinginfo"
              >
                Billing Info
              </a>
            </span>
          </div>
          <div class="-line" />
          <div class="margin-y-2">
            <h3 class="display3a -lite margin-y-1">Payments</h3>
            <span class="row">
              <div class="col-2-4 margin-y-1">
                <h6 style="margin: 0px;">Last Payment Date</h6>
                <h4 class="display4a -extra-lite">
                  {formatDate(subscription?.next_assesment_at - 2629800)}
                </h4>
              </div>
              <div class="col-2-4 margin-y-1">
                <h6 style="margin: 0px;">Amount</h6>
                <h4 class="display4a -extra-lite">
                  {formatBalance(subscription?.balance)}
                </h4>
              </div>
            </span>
            <h4 class="display4a -extra-lite" />
            <span class="margin-y-2">
              {/* <a class="link -blue margin-right-2" href="">Payment History</a> */}
              <a
                class="link -blue margin-right-2"
                href="/dashboard/account/payment-methods"
              >
                Payment Methods
              </a>
            </span>
          </div>
        </span>
        <span class="col-2-4">
          <h3 class="display3a -lite margin-y-1">Account Info</h3>
          <h6 class="margin-y-1">Referral Code Redemtions</h6>
          <h4 class="display4a -extra-lite" style="margin-top: 0px;">
            {customer?.referral?.redemptions}
          </h4>
          <h6 class="margin-y-1">Licenses Consumed</h6>
          <h4 class="display4a -extra-lite" style="margin-top: 0px;">
            {subscription?.consumption}
          </h4>
          {coreState.role == "owner" && (
            <>
              <h6 class="margin-y-1">Number of Team Members</h6>
              <h4 class="display4a -extra-lite" style="margin-top: 0px;">
                {employee?.length}
              </h4>
            </>
          )}
          <div class="input_container col-1-2 margin-y-1">
            <input
              type="number"
              value={subscription?.licenses}
              ref={deviceNum}
            />
            <label for="platform">Number of Licenses</label>
          </div>
          <div class="col-2-4 column align-left">
            <button
              class="button -transparent"
              style="--button-color-b: var(--brand-charcoal);"
              onClick={updateDevices}
            >
              Update Subscription
            </button>
            {subscription?.cancelling ? (
              <a
                href="#"
                class="align-left margin-y-1"
                style="color: var(--brand-blue); border: none; background: none; font-weight: 700; text-decoration: none;"
                onClick={toggleCancelModal}
              >
                Resume Subscription
              </a>
            ) : (
              <a
                href="#"
                class="align-left margin-y-1"
                style="color: var(--brand-red); border: none; background: none; font-weight: 700; text-decoration: none;"
                onClick={toggleCancelModal}
              >
                Cancel Subscription
              </a>
            )}
          </div>
        </span>
      </div>
      {subModal && (
        <UpdateSubscription parent={{ deviceNum, coreState, toggleSubModal }} />
      )}
      {cancelModal && (
        <CancelSubscription
          parent={{ toggleCancelModal, coreState, subscription }}
        />
      )}
      {cancelModal && <overlay class="overlay" />}
    </section>
  );
};
