/* eslint-disable no-unused-vars */
import { h } from "preact";
import api from "../../Utilities/API/api";
import PaymentMethodCard from "./PaymentMethodCard";
import AddPaymentMethod from "./AddPaymentMethod";
import "../../style/components/blocks/payment_method.css";
import WritingNumber from "./WritingNumber";
import { useState, useEffect, useContext } from "preact/hooks";
import { CoreState } from "../../Utilities/contextLibrary";

export default function PaymentMethod(props) {
  const { title = "Payment Methods", isRemittance = false, items, writingNums = null } = props;
  const [ isCommission, setIsCommission ] = useState(false);
  const { coreState, coreDispatch } = useContext(CoreState);
  const local = Boolean(
    process.env.PREACT_APP_COGNITO_APP_URL.includes("localhost:8080")
  );
  let checkoutQueryParams = new URLSearchParams();

  if (local) checkoutQueryParams.append("local", "yes");
  const addPaymentMethod = async () => {
    const checkout = await api.get(
      coreState.tokens.id_token,
      `billing/checkout/add?${checkoutQueryParams}`,
      "apiv5"
    );
    if (!(checkout.status == 200)) return alert(checkout.response.message);
    window.location.assign(checkout.response.sessionURL);
    console.log("Checkout:", checkout);
  };
  useEffect(() => {
    if(isRemittance) {
      setIsCommission(true);
    }
  }, [isRemittance]);
  
  return (
    <div className="payment-method">
      <div className="payment-method__header">
        {/* <h2 className="payment-method__title">{title}</h2> */}
        <h2 className="display2 -extra-lite">{title}</h2>

        {
          writingNums &&
            <form className="payment-method__header-form">
            <div className="payment-method__header-form-radio">
              <label className="payment-method__header-form-label">
                <input
                  name="payment-method"
                  type="radio"
                  value="use_credit_card"
                  checked={!isCommission}
                  onChange={(e) => setIsCommission(false)}
                  className="payment-method__header-form-input"
                />
                Use Credit Card
              </label>
            </div>

            <div className="payment-method__header-form-radio">
              <label className="payment-method__header-form-label">
                <input
                  name="payment-method"
                  type="radio"
                  value="use_writing_number"
                  checked={isCommission}
                  onChange={(e) => setIsCommission(true)}
                  className="payment-method__header-form-input"
                />
                Use Writing Number
              </label>
            </div>
          </form>
        }
      </div>



          <div className="payment-method__list">
          <div className="payment-method__list-header">
            <h3 className="payment-method__list-title">{isCommission ? "Writing Number for statement deduction" : "Saved Payment Methods"}</h3>
            {
              !isCommission &&
                <button  onClick={addPaymentMethod} className="payment-method__header-add-payment-btn">
                  + Add Payment Method
                </button>
            }
          </div>
              {
                !isCommission ?
                  <div className="payment-method__list-grid">
                    {items &&
                      items.map((item, index) => (
                        <PaymentMethodCard key={index} {...item} />
                        ))}
                    <div className="payment-method__list-add-payment-card">
                      <AddPaymentMethod addPaymentMethod={addPaymentMethod} />
                    </div>
                  </div>
                        : 
                  <WritingNumber
                    writingNums={writingNums}
                    />
              }
        </div>
    </div>
  );
}
