/* eslint-disable no-unused-vars */
import { h } from "preact";

import StyledRichtext from "../StyledRichtext";

import "../../style/components/blocks/included_logos.css";

export default function IncludedLogos(props) {
  const { title = "What's inlcuded", logos, textBlocks } = props;

  return (
    <div className="included-logos" {...props}>
      <div className="included-logos__content">
        <div className="included-logos__logos">
          <h2 className="included-logos__heading">{title}</h2>

          <div className="included-logos__grid">
            {logos &&
              logos.map((logo, index) => (
                <div className="included-logos__grid-item" key={index}>
                  <img
                    src={logo?.image?.url}
                    className="included-logos__grid-item-image"
                    alt=""
                  />
                </div>
              ))}
          </div>
        </div>

        <div className="included-logos__text">
          {textBlocks &&
            textBlocks.map((block, index) => (
              <div className="included-logos__text-block" key={index}>
                <StyledRichtext content={block?.textblock} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
