import { h } from 'preact';
import SelectField from '../SelectField';

const WritingNumber = (props) => {
  const { label = 'Writing Number', writingNums } = props;

  return (
    <>
      {/* <h1>{label}</h1> */}
      <p>Select the writing number you'd like to bill with.</p>
      <SelectField
        name="writing_number"
        id="writing_number"
        label="Select Writing Number"
        options={writingNums}
        />
    </>
  );
}

export default WritingNumber;