// Components
import PlanSelectionPage from '../../components/PlanSelection/PlanSelectionPage';

const Register = () => {
	return (
		<div class={`bg-white padding-large`}>
			<PlanSelectionPage />
		</div>
	)
};

export default Register;
