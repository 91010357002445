import { h } from 'preact';
import { useEffect, useRef, useState, useContext } from 'preact/hooks';
import authWrapper from '../../Utilities/authGarage/authGarage';
import { CoreState } from '../../Utilities/contextLibrary';

const CreateAuth = ({ context }) => {
    const {authState, setAuthState} = useContext(context);
    const { coreState, coreDispatch } = useContext(CoreState);
    const [prompt, setPrompt] = useState({
        title: 'Create Account',
        content: '',
        isError: false
    })
    const handleSSO = async e => {
        e.preventDefault();
        const handshake = await authWrapper.generate();
        const { pkce, challenge } = handshake;
        coreDispatch({ type: 'handshake', payload: handshake });
		coreDispatch({ type: 'authPhase', payload: 'receive' });
        const provider = e.target.id;
        authWrapper.initiate({ pkce, challenge, provider });
    };

    const [pLength, setpLength ] = useState(false); 
    const [pUp, setpUp ] = useState(false);
    const [pLow, setpLow ] = useState(false);
    const [pNum, setpNum ] = useState(false);
    const [pSpec, setpSpec ] = useState(false);
    const [match, setMatch ] = useState(false);
    const passValid = pLength && pUp && pLow && pNum && pSpec && match ? true : false;
    const refs = {
        name: {
            first: useRef(),
            last: useRef()
        },
        email: useRef(),
        password: useRef(),
        password_match: useRef(),
        requirements: useRef(),
        next: useRef()
    }
    const passwordFocusin = () => {
        refs.requirements.current.classList.add('show'); 
        refs.requirements.current.classList.remove('hide');
    }
    const passwordBlur = () => refs.requirements.current.classList.add('hide') && refs.requirements.current.classList.add('show');

    const validateRegex = e => {
        setpLength(/^.{12,}$/.test(e.target.value));
        setpUp(/[A-Z]/g.test(e.target.value));
        setpLow(/[a-z]/g.test(e.target.value));
        setpNum(/[0-9]/g.test(e.target.value));
        setpSpec(/[#?!@$ %^&*-]/g.test(e.target.value));
        setMatch(refs.password_match.current.value == refs.password.current.value ? true : false)
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const email = refs.email.current.value
        const pass = refs.password.current.value

        const { status, headers, response } = await authWrapper.signup({
            username: email,
            password: pass,
            attributes: {
                given_name: refs.name.first.current.value,
                family_name: refs.name.last.current.value
            }
        })

        if (status != 200) {
            setPrompt({...prompt,
                content: headers['x-amzn-errormessage'],
                isError: true
            })
            return
        }

        setAuthState({...authState,
            stage: 'confirm-email',
            user: {
                email: email,
                pass: pass
            }
        })

    }
    return (
        <div class="padding-medium">
            <div class="column margin-x-auto margin-y-1 col-1-3">
                <h1 class="align-center padding-y-1 display1 -extra-lite">{prompt.title}</h1>
                <p class={`${prompt.isError} align-center -extra-lite`}>{prompt.isError ? 'Error: ': null}{prompt.content}</p>
                <p class="align-center -extra-lite">Add info to create a starter account</p>
                <a class="button sso" onClick={handleSSO} href="#"><img class="align-left" src="/assets/images/icons/icon-google.png" />Continue with Google<span></span></a>
                <br/>
                <a class="link align-center" href="/auth?provider=Aflac">Sign in with your Aflac account</a>
                <p class="margin-y-1 align-center">or</p>
                <form onSubmit={handleSubmit}>
                    <div class="input_container">
                        <input 
                            ref={refs.name.first}
                            required
                            type="text" 
                            id="firstName" />
                        <label for="firstName">First Name</label>
                    </div>
                    <div class="input_container">
                        <input 
                            ref={refs.name.last}
                            required
                            type="text" 
                            id="lastName" />
                        <label for="lastName">Last Name</label>
                    </div>
                    <div class="input_container">
                        <input 
                            ref={refs.email}
                            required
                            type="email" 
                            id="email" />
                        <label for="email">Email</label>
                    </div>
                    <div class="input_container">
                        <input 
                            ref={refs.password}
                            required
                            type="password"
                            id="password"
                            onFocus={passwordFocusin}
                            // onBlur
                            onChange={validateRegex}
                            autoComplete="new-password"
                            class={`${pLength && pUp && pLow && pNum && pSpec ? null : 'input_invalid'} password`} />
                        <label for="password">Create Password</label>
                    </div>
                    <div class="input_container">
                        <input 
                            ref={refs.password_match}
                            required
                            type="password"
                            id="passwordMatch"
                            onFocus={passwordFocusin}
                            // onBlur={passwordBlur} 
                            onChange={validateRegex}
                            />
                        <label for="passwordMatch">Confirm Password</label>
                    </div>
                    <div class="padding-y-1 hide" ref={refs.requirements} id="password-requirements">
                        <h4 class="display4 -red">Password Requirements</h4>
                        <span class="row" id="password_message">
                            <div class="margin-right-2">
                                <p class={pLength ? "valid" : "invalid"} id="length">Min. length 12</p>
                                <p class={pUp ? "valid" : "invalid"} id="uppercase">1 uppercase letter</p>
                                <p class={pLow ? "valid" : "invalid"} id="lowercase">1 lowercase letter</p>
                            </div>
                            <div >
                                <p class={pNum ? "valid" : "invalid"} id="number">1 number</p>
                                <p class={pSpec ? "valid" : "invalid"} id="special">1 #?!@$ %^&*-</p>
                                <p class={match ? "valid" : "invalid"} id="match">Passwords match</p>

                            </div>
                        </span>
                    </div>
                    <div class="row margin-y-1">
                        <button class="back" style="background: white; border: none; width: 50%; font-size: 18px; font-weight: 600;" type="back">Back</button>
                        <button class={passValid ? 'show' : 'hide'} style="background: #01A7E1; border: none; width: 50%; color: white; padding: 18px; font-size: 18px; font-weight: 600;" type="submit">Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default CreateAuth;