import { h } from "preact";
import { useRef } from "preact/hooks";

import api from "../../Utilities/API/api";
import states from "../../Utilities/states";
import Button from "../Button";
import InputField from "../InputField";
import SelectField from "../SelectField";
import "../../style/components/blocks/info.css";

export const BillingInfo = ({ props }) => {
  console.log("props: ", props);
  const { orgInfo, coreState } = props;
  const formRef = useRef();
  const refs = {
    name: useRef(), 
    address: {
      line1: useRef(),
      line2: useRef(),
    },
    city: useRef(),
    state: useRef(),
    zip: useRef(),
    phone: useRef(),
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    console.log("formData: ", formData);

    const infoObject = {
      name: formData.get('name'),
      address: {
        line1: formData.get('address'),
        line2: formData.get('addressTwo'),
      },
      city: formData.get('city'),
      state: formData.get('state'),
      zip: formData.get('zip'),
    };
    console.log("infoObject: ", infoObject);
    api
      .put(coreState.tokens.id_token, "organization/info", infoObject)
      .then((res) => {
        if ("message" in res) {
          window.alert(
            "Looks like we hit a snag, if this is your first time seeing this message click ok and try again, otherwise please contact our support team for further assistance. ."
          );
          window.location.reload();
        } else {
          console.log("res: ", res);
          window.location.reload();
        }
      });
  };
  return (
    orgInfo && (
      <section>
        <h2 className="display2 -extra-lite">Organization Info</h2>
        <form class={`billing_form`} onSubmit={handleSubmit} ref={formRef}>
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridAutoRows: "1fr",
              gap: "24px",
            }}
          >
            <div class="span-12">
              <InputField
                label="Organization Name"
                name="name"
                type="text"
                placeholder="Organization Name"
                defaultValue={orgInfo.name}
                />
            </div>
            {/* <div class="span-12">
              <InputField
                label="Street Address"
                name="address"
                type="text"
                placeholder="Street Address"
                defaultValue={orgInfo?.address?.line1}
              />
              </div>
              <div class="span-12">
                <InputField
                  label="Street Address 2"
                  name="addressTwo"
                  type="text"
                  placeholder="Street Address 2"
                  defaultValue={orgInfo?.address?.line2}
                />
              </div>
              <div class="span-4">
                <InputField
                  label="City"
                  name="city"
                  type="text"
                  placeholder="City"
                  defaultValue={orgInfo?.city}
                />
              </div>
              <div class="span-4">
                <SelectField
                  label="State"
                  name="state"
                  options={states}
                  placeholder="State"
                  defaultValue={orgInfo?.state}
                />
              </div>
              <div class="span-4">
                <InputField
                  label="Zip Code"
                  name="zip"
                  type="number"
                  placeholder="Zip Code"
                  defaultValue={orgInfo?.zip}
                />
              </div> */}
              {/* <div class="span-12">
                <InputField
                  label="Phone Number"
                  name="phone"
                  type="tel"
                  placeholder="Phone Number"
                  defaultValue={orgInfo?.phone}
                />
              </div> */}
              <div class="span-12">
                <Button
                  type="submit"
                  label="Update"
                />
              </div>
          </div>
        </form>
      </section>
    )
  );
};
