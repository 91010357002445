import { h } from "preact";
import { useRef } from "preact/hooks";

import authWrapper from "../../Utilities/authGarage/authGarage";

export const AccountPreferences = ({ props }) => {
  console.log("props: ", props);
  const { coreState, coreDispatch } = props;
  const refs = {
    name: {
      first: useRef(null),
      last: useRef(null),
    },
    password: {
      current: useRef(null),
      new: useRef(null),
      confirm: useRef(null),
    },
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const attributes = {
      given_name: refs.name.first.current.value,
      family_name: refs.name.last.current.value,
      password: refs.password.confirm,
    };
    const accessToken = coreState.tokens.access_token;

    console.log(accessToken);

    authWrapper.updateAttributes({ accessToken, attributes }).then((res) => {
      authWrapper.refresh(coreState.tokens.refresh_token).then((res) => {
        const { response, status } = res;
        if (status !== 200) {
          console.log("Error refreshing tokens: ", response);
        } else {  
          refreshResponse = {
            refresh_token: coreState.tokens.refresh_token,
            access_token: response.AuthenticationResult.AccessToken,
            id_token: response.AuthenticationResult.IdToken,
            expiration: Math.floor(Date.now() / 1000) + response.AuthenticationResult.ExpiresIn
        }
        coreDispatch({type: 'tokens', payload: refreshResponse}) 
        authWrapper.userinfo(res.id_token).then((res) => {
          console.log("userinfo: ", res);
          coreDispatch({ type: "userinfo", payload: res });
        });
        }
      });

      parent.infoDispatch({ type: "infoObject", payload: infoObject });
    });
  };
  return (
    <section>
      <h2 className="display2 -extra-lite">Account Preferences</h2>
      <form class={`billing_form`} onSubmit={handleSubmit}>
        <div class={`input_container`}>
          <input
            ref={refs.name.first}
            required
            type="text"
            id="first_name"
            value={coreState.userinfo?.given_name}
          />
          <label for="first_name">First Name</label>
        </div>
        <div class={`input_container`}>
          <input
            ref={refs.name.last}
            required
            type="text"
            id="last_name"
            value={coreState.userinfo?.family_name}
          />
          <label for="last_name">Last Name</label>
        </div>
        {coreState.userinfo.provider === "Native" && (
          <>
            <div class={`input_container`}>
              <input
                ref={refs.password.current}
                required
                type="text"
                id="current_pass"
              />
              <label for="current_pass">Current Password</label>
            </div>
            <div class={`input_container`}>
              <input
                ref={refs.password.new}
                required
                type="text"
                id="new_pass"
              />
              <label for="new_pass">New Password</label>
            </div>
            <div class={`input_container`}>
              <input
                ref={refs.password.confirm}
                required
                type="text"
                id="confirm_pass"
              />
              <label for="confirm_pass">Confrim Password</label>
            </div>
          </>
        )}

        <div class={`input_container`}>
          <button class={`button -blue`} type="submit">
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};
