import { h } from 'preact';
import iconCheck from '../../assets/images/icons/icon-check.svg';
export default ({issues}) => {
    
    // console.log('issues: ', issues);
    return (
        (issues.length == 0) ? 
            <fragment>
                <div class="bg-light-green padding-2 align-center margin-y-1 justify-center">
                    <h3 class="display3b -lite"><img src={iconCheck} /><span style="color: var(--brand-green)">Zero</span> current issues!</h3>
                    <p>Everything has been checked and you are safe.</p>
                </div>
                {/* <div class="bg-lighter-blue row padding-2 margin-y-1 space-evenly vertical-center">
                    <span>
                        <h3 class="display3b -lite"><img src="/assets/images/icons/icon-check.svg" /><span style="color: var(--brand-blue)">60 Days</span> Since your last issue</h3>
                        <p>Wow! great job keep it up.</p>
                    </span>
                    <span>
                        <img src="/assets/images/icons/60-day-badge.svg" />
                    </span>
                </div> */}
            </fragment>
            :
            issues.map( issue =>  {
                return (
                    <Issue issue={issue} />
                )
            } )
    ) 
}

const Issue = ({issue}) => {
    const formatDate = (date) => {
		return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(new Date(date));
	}
    // console.log('issue: ', issue);
    return (
        <div class="bg-grey padding-small margin-y-1 space-between">
            <span class="row space-between">
                <span class="row" style="width: 90%; justify-content: left; align-items: center;">
                    <p style="background-color: var(--brand-orange); width: 33%; border-radius: 100px; line-height: 20px; height: min-content; min-width: max-content; padding: 0px 18px; color: var(--brand-white); font-size: 11px; font-weight: 700; text-align: center;">
                        {issue.report.status}
                    </p>
                    <p style="font-size: 16px; font-weight: 700; margin-left: 10px;">
                        {formatDate(issue.report.timestamp)}
                    </p>
                    <p style="font-weight:400; font-size: 14px; line-height: 30px;">
                        {issue.host}
                    </p>
                </span>
                <span style="font-weight:400; font-size: 14px; text-align: right; width 5%;">
                    {issue.report.severity}
                </span>
            </span>
            <p style="font-weight:400; font-size: 14px;">{issue.report.action} | {issue.report.tandt} <a href='emailto:cyberprotection@aflac.com'>Contact Support</a></p>
        </div>
    );
}