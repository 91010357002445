import { createRef } from "preact";
import { route } from "preact-router";
import { useContext, useState } from "preact/hooks";
import authWrapper from '../../Utilities/authGarage/authGarage';
import { CoreState } from "../../Utilities/contextLibrary";
import LogRocket from 'logrocket';
import analytics from '../../Utilities/analytics';
import api from "../../Utilities/API/api";


const PromptLogin = () => (
    <>
        Confirmation failed, please try again: <a href="?stage=login">Login</a>
    </>
)
const InvalidCode = () => (
    <>
        Invalid Code: Please verify the accuracy of your code, and try again.
    </>
)

export default ({ context, listenerName }) => {
    const {authState, setAuthState} = useContext(context)
    const { coreDispatch } = useContext(CoreState);

    const codeRef = createRef(null)

    const [prompt, setPrompt] = useState({
        title: 'Complete MFA', 
        content: 'Enter the code generated by your authenticator app',
        isError: false
    })
    const previousPage = () => {

    }
    const handleSubmit = async e => {
        e.preventDefault();

        console.log('submitting')
        const code = codeRef.current.value
        codeRef.current.value = null

        // NEED TO HANDLE STATE
        const { status, headers, response } = await authWrapper.challengeResponder(
            'SOFTWARE_TOKEN_MFA',
            {
                'USERNAME': authState.user.email,
                'SOFTWARE_TOKEN_MFA_CODE': code
            },
            authState.user.session
        )

        if (status != 200) {
            setPrompt({...prompt,
                content: (() => {
                    switch (headers['x-amzn-errortype']) {
                        
                        case 'NotAuthorizedException:': return <PromptLogin />
                        case 'CodeMismatchException:': return <InvalidCode />
                        default: return headers['x-amzn-errormessage']
                    }
                })(),
                isError: true
            })
            return
        } else {
            const tokens = authWrapper.parseAuthenticationResult(response.AuthenticationResult);
            const identityToken = authWrapper.userinfo(tokens.id_token);

            console.log('USERINFO: ', identityToken)

            coreDispatch({ type: 'tokens', payload: tokens});
            coreDispatch({ type: 'userinfo', payload: identityToken});

            console.log('dispatch complete')

            LogRocket.identify(identityToken.email, {
				name: identityToken.given_name + ' ' + identityToken.family_name,
				uuid: identityToken.sub
			  });
			// Initialize logging to GA for user session tracking
			analytics.identify(identityToken.email, {
				name: identityToken.given_name + ' ' + identityToken.family_name,
				uuid: identityToken.sub
			});

            console.log('LR and ANALYTICS complete')

            try {
                const customer = await api.get(tokens.id_token,'customer/', 'apiv5');
                console.log('CUSTOMER: ', customer.response);
                coreDispatch({ type: 'customer', payload: customer.response });
                coreDispatch({ type: 'role', payload: customer.response.CLIENTSTATUS });
                coreDispatch({ type: 'isAuth', payload: true });
                setAuthState({...authState, stage: 'route-user'});
            } catch (e) {
                console.log('Error: ', e);
                if ('message' in customer.response) {
                    // console.log('error: customer registration failed', res);
                    window.alert('looks like we hit a snag, if this is your first time seeing this message please refresh the page and try again, otherwise please contact support for further assistance.');
                    window.location.reload();
                }
            }
        }
    }

    return (
        <section class="padding-large">
            <div class="column margin-x-auto margin-y-1 col-1-3">
                <h1 class="align-center padding-y-1 display1 -extra-lite">{prompt.title}</h1>
                <p class={`${prompt.isError} align-center -heavy`}>{prompt.isError ? 'Error: ': null}{prompt.content}</p>
                <form onSubmit={handleSubmit}>
                    <div class="input_container">
                        <input 
                            ref={codeRef}
                            type="tel"
                            autofocus 
                            id="totp" />
                        <label for="totp">Token</label>
                    </div>
                    <div class="row margin-y-1">
                        {/* <button class="back" style="background: white; border: none; width: 50%; font-size: 18px; font-weight: 600;" type="button" onClick={previousPage}>Back</button> */}
                        <button style="background: #01A7E1; border: none; width: 100%; color: white; padding: 18px; font-size: 18px; font-weight: 600;" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </section>
    )
}