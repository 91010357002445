import { h, Component } from 'preact';
import FailedContactUs from '../../components/FailedContactUs';

const Failed = () => {
    return (
        <div style={{
            backgroundColor: 'white',
            overflow: 'hidden',
        }}>
            <FailedContactUs />
        </div>
    );
};

export default Failed;