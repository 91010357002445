import { h } from "preact";
import React from "react";
// components
import { Link } from 'preact-router/match';
// modules
import { route, Router } from 'preact-router';
// utility functions
import { useWindowSize } from '../../Utilities/window';
// state
import { CoreState } from '../../Utilities/contextLibrary';
// hooks
import { useContext, useEffect, useState } from 'preact/hooks';

export default function Header(props) {
	const {
		highContrast,
		setContrast
	} = props;
	const { coreState, coreDispatch } = useContext(CoreState);
	const size = useWindowSize();
	const [ navbarOpen, setNavbarOpen ] = useState(size.width > 1190 ? true : false);
	const [ role, setRole ] = useState(false);
	const handleToggle = () => {
		setNavbarOpen(prev => !prev);
	}
	const hanldeRoute = () => {
		setNavbarOpen(false);
	}
	return (
		<>
		<Router onChange={hanldeRoute} />
			{size.width > 1190 && (<><Secondary props={{ highContrast, setContrast, coreState }} /></>)}
			<header class={`header`}>
				<h1 class={`menu`}>
					<a href="/" aria-label="Aflac Cyber Security">
						<img href="/" src="/assets/images/logo.webp" alt="" role="presentation" />
					</a>
					{size.width > 1190 && (<><Pages props={{coreState}} /><Actions props={{coreState, coreDispatch, handleToggle}} /></>)}
					{
						size.width < 1190 &&
							<a onClick={handleToggle} title="Toggle Menu" aria-label="Toggle Menu" class={`toggle`}>
								<svg class={`hamburger`} role="presentation" focusable="false">
									<use href="./assets/symbols.svg#menu" />
								</svg>
							</a>
					}
				</h1>
				{size.width < 1190 && (
				<>
				{navbarOpen && (
					<>
					<Pages props={{coreState}} />
					<Secondary props={{ highContrast, setContrast, coreState }} />
					<Actions props={{coreState, coreDispatch, handleToggle}} />
					</>
				) }
				</>
				)
			}
			{
				!['/benefit','/maintenance', '/', '/not_found' ].includes(window.location.pathname) && (
					<div class={`breadcrumb`}>
						<span class={`breadcrumb_page`}><h4>{route.path}</h4></span>
					</div>
				)
			}

			</header>
			<overlay class={navbarOpen  && size.width < 1190 ? `overlay` : `hide`}/>
		</>
	)
}

const Pages = ({props}) => {
	const {
		coreState
	} = props;
	const [ isPro, setIsPro] = useState(false);
	const [ hasTeam, setHasTeam ] = useState(false);
	const [ isActive, setActive ] = useState(false);
	const [ hidePages, setHidePages ] = useState(false);
	useEffect(() => {
		setHidePages(!['/benefit', '/maintenance', '/auth*', 'register'].includes(coreState.header_view));
	},[hidePages])
	useEffect(() => {
		setActive((coreState.isActive && coreState.isAuth));
	},[coreState.isActive, coreState.isAuth])
	useEffect(() => {
		console.log('Has customer: ', coreState.customer);
		if ('customer' in coreState) {
			setHasTeam(['employee.owner', 'employee.admin'].includes(coreState.role));
			setIsPro(['employee.owner'].includes(coreState.role));
		}
	},[coreState.role])

	if (window.location.pathname.includes('register')) return
	return (
		<nav class={`pages`} >
			<PageItem text="Blog" href="/blog" />
			<PageItem text="About" href="/about" />
			{isActive &&
			<>
				<PageItem text="Dashboard" href="/dashboard" />
				<PageItem text="Security Check" href="/device_scan" />
				<PageItem text="Guides" href="/guides" />
				{isPro && <PageItem text="Invoices" href="/dashboard/account/billing" />}
				{hasTeam && <PageItem text="Team" href="/dashboard/team" />}
			</>
			}
		</nav>
	)
}

const Actions = ({props}) => {
	const { coreState, coreDispatch } = props;
	const handleLogout = () => {
		coreDispatch({type: 'logoutPhase', payload: 'initiateLogout'});
		route('/logout');
	}

	return (
			!['/benefit','/maintenance'].includes(coreState.header_view) ? (
				<nav class={`actions`}>
					{coreState.isAuth  ? (
					<>
						{((!window.location.pathname.includes('register') && coreState.isActive)) && <Link class={`manage_account`} activeClassName={`actionActive`} href={['owner', 'associate'].includes(coreState.role) ? '/dashboard/account' : '/dashboard/account/preferences'}>Manage Account</Link>}
						<Link class={`logout`} activeClassName={`actionActive`} href="#" onClick={handleLogout}>Logout</Link>
					</>
					) : (
					<>
						<ActionItem text="Login" href="/login" />
						<Link class={`register`} activeClassName={`actionActive`} href="/register">Get Started</Link>
					</>
					)}
				</nav>
			) : null
	)
}

const Secondary = ({ props }) => {
	const {
		highContrast,
		setContrast,
		coreState
	} = props;
	return (
		<>
			{
				(coreState.header_view) != ('/benefit') ? (
					<nav class={`secondary`}>
						<span class={`support`}>
							<a href="tel:+18442042206">
								<support class={`support_mobile`}>
									Customer Support
									<phone class={`support_desktop`}>
										<number>
											 (844) 204-2206
										</number>
										<hours>
											 M-F 7a.m.-7p.m.
										</hours>
									</phone>
								</support>
							</a>
						</span>
						<span class={`contrast`}>
							<a onClick={setContrast} class={`contrast_button`}>High Contrast Mode</a>
							<a onClick={setContrast} id="contrast" class={!highContrast ? `contrast_input` : `contrast_input_on`} />
						</span>
					</nav>
				) : null
			}
		</>
	)
}

const ActionItem = ({ text, href }) => {
	return <Link
		class={`actionItem`}
		activeClassName={`actionActive`}
		href={href}>
	{text}
	</Link>
}

const PageItem = ({ text, href }) => {
	return <Link
		class={`display3b || pageItem`}
		activeClassName={`pageActive`}
		href={href}>
	{text}
	</Link>
}

export {
	PageItem,
	ActionItem,
	Pages,
	Actions
}
